import { Box, Card, Grid, Typography } from "@mui/material";
import { getUserById } from "api/services/user";
import { NameDetail } from "components/NameDetail";
import moment from "moment";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { StyledFlexBox } from "styles";

export const BasicInfo = () => {
  const { id } = useParams();

  const { data, isLoading } = useQuery(["userById", id], getUserById);

  return (
    <Box>
      <StyledFlexBox justifyContent="space-between" mb={2}>
        <Typography variant="h4">Basic Information</Typography>
      </StyledFlexBox>
      <Card>
        <Grid container spacing={3} p={3}>
          <Grid item xs={12} md={2}>
            <NameDetail title="Name" detail={data?.data?.name} />
          </Grid>
          <Grid item xs={12} md={3}>
            <NameDetail title="Email Address" detail={data?.data?.email} />
          </Grid>
          <Grid item xs={12} md={2}>
            <NameDetail
              title="Mobile Number"
              detail={data?.data?.mobileNumber}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <NameDetail title="Gender" detail={data?.data?.gender} />
          </Grid>
          <Grid item xs={12} md={3}>
            <NameDetail
              title="Date of Birth"
              detail={moment(data?.data?.dateOfBirth).format("DD-MMMM-YYYY")}
            />
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};
