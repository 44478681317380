import loadable from "@loadable/component";

export const Dashboard = loadable(() => import("pages/dashboard"));
export const login = loadable(() => import("pages/login"));
export const Feed = loadable(() => import("pages/vkinnectFeed"));
export const ServiceCategory = loadable(
  () => import("pages/configuration/settings/serviceCategory")
);
export const ServiceType = loadable(
  () => import("pages/configuration/settings/serviceCategory/serviceType")
);
export const Services = loadable(
  () => import("pages/configuration/settings/services")
);
export const ServiceDetail = loadable(
  () => import("pages/configuration/settings/services/serviceDetail")
);
export const ServicePackageDetail = loadable(
  () =>
    import("pages/configuration/settings/servicePackges/servicePackageDetail")
);
export const Locker = loadable(
  () => import("pages/configuration/settings/locker")
);
export const ServicePackages = loadable(
  () => import("pages/configuration/settings/servicePackges")
);
export const Volunteers = loadable(() => import("pages/vkinnectVolunteers"));
export const VolunteerDetails = loadable(
  () => import("pages/vkinnectVolunteers/volunteerDetails")
);
export const BasicInformation = loadable(
  () => import("pages/vkinnectVolunteers/volunteerDetails/basic_Information")
);
export const ServiceInformation = loadable(
  () => import("pages/vkinnectVolunteers/volunteerDetails/service_Information")
);
export const LocationInformation = loadable(
  () => import("pages/vkinnectVolunteers/volunteerDetails/location_Information")
);
export const KycInformation = loadable(
  () => import("pages/vkinnectVolunteers/volunteerDetails/kyc_Information")
);
export const Documentation = loadable(
  () => import("pages/vkinnectVolunteers/volunteerDetails/documentation")
);
export const ServiceBooking = loadable(() => import("pages/serviceBooking"));
export const ServiceBookingDetails = loadable(
  () => import("pages/serviceBooking/bookingView")
);
export const ServiceInfo = loadable(
  () => import("pages/serviceBooking/bookingView/serviceInformation")
);
export const AdditionalServiceInfo = loadable(
  () => import("pages/serviceBooking/bookingView/additionalServiceInfo")
);
export const PaymentInformation = loadable(
  () => import("pages/serviceBooking/bookingView/paymentInformation")
);
export const ServiceFeed = loadable(
  () => import("pages/serviceBooking/bookingView/serviceFeed")
);
export const EmergencyAlert = loadable(() => import("pages/emergencyAlerts"));
export const Admin = loadable(() => import("pages/admin"));
