import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid, Typography } from "@mui/material";
import { login } from "api/services/user";
import { loginBackground, logoAppBar } from "assets";
import FormInput from "components/FormFields/FormInput";
import FormPasswordInput from "components/FormFields/FormPasswordInput";
import Cookies from "js-cookie";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { StyledDfAc, StyledDfAcJc } from "styles";
import { loginDefaultValues, LoginSchema } from "validations/loginForm";

const Login = () => {
  const navigate = useNavigate();

  const { mutate } = useMutation(login, {
    onSuccess: (data) => {
      Cookies.set("access_token", data?.data?.access_token);
      window.location.href = "/dashboard";
      toast.success("Login Successfully");
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message);
    },
  });

  const { control, handleSubmit } = useForm({
    defaultValues: loginDefaultValues,
    mode: "onChange",
    resolver: yupResolver(LoginSchema),
  });

  const onFormSubmit = (data: any) => {
    mutate(data);
  };

  return (
    <Grid container>
      <Grid item xs={8}>
        <Box>
          <img
            src={loginBackground}
            alt="login"
            style={{ objectFit: "cover", width: "100%", height: "100vh" }}
          />
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Box>
          <StyledDfAcJc
            sx={{ flexDirection: "column", height: "80vh", padding: "20px" }}
          >
            <img src={logoAppBar} alt="logo" width={250} height={80} />
            <Box mt={4} width="100%" maxWidth={350}>
              <Box>
                <Typography variant="h2">Login to your account!</Typography>
                <Typography variant="body2">
                  Please enter the following details to continue
                </Typography>
              </Box>
              <form onSubmit={handleSubmit(onFormSubmit)}>
                <Box mt={3}>
                  <FormInput
                    control={control}
                    name="username"
                    label="Username"
                  />
                </Box>
                <Box mt={2}>
                  <FormPasswordInput
                    control={control}
                    name="password"
                    label="Password"
                  />
                </Box>
                <Box mt={2}>
                  <Button type="submit" variant="contained" fullWidth>
                    login
                  </Button>
                </Box>
              </form>
            </Box>
            <StyledDfAc mt={3}>
              {/* <Typography variant="body1">Forgot Your Password ? </Typography>
              <Button>click here</Button> */}
            </StyledDfAc>
          </StyledDfAcJc>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Login;
