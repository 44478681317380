import { ListItemIcon, ListItemText, Typography } from "@mui/material";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import { icons } from "assets";
import { Link, useLocation } from "react-router-dom";
import { StyledSideNav } from "styles";

const SideBar = () => {
  const location = useLocation();

  const sideNavList = [
    {
      title: "Dashboard",
      path: "/dashboard",
      icon: icons.dashboardIcon,
    },
    {
      title: "Service Bookings",
      path: "/service-bookings",
      icon: icons.serviceBookingIcon,
    },
    {
      title: "VKinnect Associates",
      path: "/vkinnect-associates",
      icon: icons.vkinnectAssociatesIcon,
    },
    {
      title: "VKinnect Feed",
      path: "/vkinnect-feed",
      icon: icons.vkinnectFeedIcon,
    },
    {
      title: "Emergency Alerts",
      path: "/emergency-alerts",
      icon: icons.emergencyAlertIcon,
    },
    {
      title: "Vkinnect Admin",
      path: "/vkinnect-admin",
      icon: icons.AdminIcon,
    },
    {
      title: "Configuration",
      path: "/configuration",
      icon: icons.configurationIcon,
    },
  ];

  return (
    <StyledSideNav>
      <List sx={{ padding: "20px" }}>
        {sideNavList?.map((item: any, index: any) => (
          <Link key={index} to={item.path} style={{ textDecoration: "none" }}>
            <ListItemButton
              selected={location.pathname.startsWith(item.path)}
              sx={{
                "&.Mui-selected": {
                  background: "#00145A",
                  opacity: "1",
                },
                "&.Mui-selected:hover": {
                  background: "rgb(0, 20, 90,0.7)",
                },
                borderRadius: "15px",
              }}
            >
              <ListItemIcon sx={{ mr: -2 }}>
                <img src={item.icon} alt="" style={{ width: 24, height: 24 }} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="subtitle2" style={{ color: "#FFFFFF" }}>
                    {item?.title}
                  </Typography>
                }
              />
            </ListItemButton>
          </Link>
        ))}
      </List>
    </StyledSideNav>
  );
};

export default SideBar;
