import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Box,
  Divider,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import { icons } from "assets";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { StyledSideNav } from "styles";

const ConfigSideBar = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const sideNavList = [
    {
      title: "Service Category",
      path: "/configuration/service-category",
      icon: icons.serviceCategory,
    },
    {
      title: "Services",
      path: "/configuration/services",
      icon: icons.services,
    },
    {
      title: "Locker",
      path: "/configuration/locker",
      icon: icons.locker,
    },
    {
      title: "Service Packages",
      path: "/configuration/service-packages",
      icon: icons.servicePackage,
    },
  ];

  const iconSize = 24;

  return (
    <StyledSideNav>
      <Box mt="20px" display="flex" gap={1} px="20px">
        <ArrowBackIcon
          onClick={() => navigate("/dashboard")}
          sx={{ cursor: "pointer", color: "#FFFFFF" }}
        />
        <Typography variant="h5" fontSize="18px" color="#FFFFFF">
          Settings
        </Typography>
      </Box>
      <Divider sx={{ background: "#FFFFFF", opacity: "0.2", mt: 2 }} />
      <List sx={{ padding: "20px" }}>
        {sideNavList?.map((item: any, index: any) => (
          <Link key={index} to={item.path} style={{ textDecoration: "none" }}>
            <ListItemButton
              selected={location.pathname.startsWith(item.path)}
              sx={{
                "&.Mui-selected": {
                  background: "#00145A",
                  opacity: "1",
                },
                "&.Mui-selected:hover": {
                  background: "rgb(0, 20, 90,0.7)",
                },
                borderRadius: "15px",
              }}
            >
              <ListItemIcon sx={{ mr: -2 }}>
                <img
                  src={item.icon}
                  alt={item.title}
                  style={{ width: iconSize, height: iconSize }}
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="subtitle2" style={{ color: "#FFFFFF" }}>
                    {item?.title}
                  </Typography>
                }
              />
            </ListItemButton>
          </Link>
        ))}
      </List>
    </StyledSideNav>
  );
};

export default ConfigSideBar;
