import { Box } from "@mui/material";
import Header from "layout/mainLayout/appBar/Header";
import { Outlet } from "react-router-dom";
import ConfigSideBar from "./sideBar";

const ConfigLayout = () => {
  return (
    <Box>
      <Header />
      <ConfigSideBar />
      <Box p={2} mt={10} marginLeft="285px" marginRight="8px">
        <Outlet />
      </Box>
    </Box>
  );
};

export default ConfigLayout;
