import { Box, Typography } from "@mui/material";

export const NameDetail = ({ title, detail, mt }: any) => {
  return (
    <Box mt={mt}>
      <Typography variant="h6" sx={{ opacity: "0.7" }}>
        {title}
      </Typography>
      <Typography variant="h6">{detail}</Typography>
    </Box>
  );
};
