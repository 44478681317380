import { Box, styled, TextField, Toolbar } from "@mui/material";

export const StyledDfAcJc = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const StyledDfAc = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
}));

export const StyledDfAcJsb = styled(Toolbar)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

export const StyledTop = styled(Box)(() => ({
  zIndex: 1100,
  background: "#26A79B",
  width: "100%",
  position: "fixed",
}));

export const StyledHeader1 = styled(Box)(() => ({
  display: "flex",
  width: "140px",
  justifyContent: "space-between",
}));
export const StyledFormWrap = styled(Box)(() => ({
  marginTop: "30px",
}));
export const StyledWhitePaper = styled(Box)(() => ({
  boxShadow: "0px 6px 6px 10pxpx",
  backgroundColor: "#FFFFFF",
  borderRadius: "10px",
  padding: "18px",
  marginTop: "10px",
  marginBottom: "10px",
}));

export const StyledSideNav = styled(Box)(() => ({
  boxShadow: "0px 6px 16px #0000001A",
  width: "280px",
  height: "100vh",
  position: "fixed",
  backgroundColor: "#000929",
}));
export const StyledEndPoint = styled(Box)(() => ({
  "@media (min-width: 1000px)": {
    display: "none",
  },
}));
export const StyledEndPointMax = styled(Box)(() => ({
  "@media (max-width: 1000px)": {
    display: "none",
  },
}));

export const StyledSideServiceHeader = styled(Box)(() => ({
  boxShadow: "0px 6px 15px #3154981A",
  border: "1px solid #3154981A",
  borderRadius: "10px",
  padding: "10px 0",
}));

export const StyledSideServiceWrap = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "0 16px",
  paddingBottom: "10px",
}));

export const StyledServiceTextField = styled(TextField)(() => ({
  "& .MuiInputBase-root": {
    height: "40px",
  },
  "input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button":
    {
      WebkitAppearance: "none",
      margin: 0,
    },
}));

export const BpIcon = styled("span")(({}) => ({
  borderRadius: 3,
  width: 18,
  height: 18,
  marginLeft: "2px",
  border: "1px solid #CBCBCB",
  backgroundColor: "#F8F8F8",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: "#F8F8F8",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background: "rgba(206,217,224,.5)",
  },
}));

export const BpCheckedIcon = styled(BpIcon)(({ theme }) => ({
  backgroundColor: "#F8F8F8",
  border: `1px solid ${theme.palette.primary.main}`,
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  marginLeft: "2px",
  "&:before": {
    content: '""',
    display: "block",
    width: 8,
    height: 8,
    margin: "4px",
    left: "2px",
    background: theme.palette.primary.main,
    borderRadius: "2px",
  },
  "input:hover ~ &": {
    backgroundColor: "#F8F8F8",
  },
}));

export const StyledFlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
}));

export const StyledPdfBox = styled(Box)(() => ({
  margin: "8px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "end",
  alignItems: "center",
  maxWidth: "300px",
  height: "200px",
  borderRadius: "10px",
  border: "1px solid #3154981A",
}));
