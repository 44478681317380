import ConfigLayout from "layout/configLayout";
import Layout from "layout/mainLayout";
import { BasicInfo } from "pages/admin/adminDetails/basicInfo";
import EmergencyAlerts from "pages/emergencyAlerts";
import Login from "pages/login";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import {
  AdditionalServiceInfo,
  Admin,
  BasicInformation,
  Dashboard,
  Documentation,
  Feed,
  KycInformation,
  LocationInformation,
  Locker,
  PaymentInformation,
  ServiceBooking,
  ServiceBookingDetails,
  ServiceCategory,
  ServiceDetail,
  ServiceFeed,
  ServiceInfo,
  ServiceInformation,
  ServicePackageDetail,
  ServicePackages,
  Services,
  ServiceType,
  VolunteerDetails,
  Volunteers,
} from "./routeImports";

const RoutesContainer = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Navigate to="dashboard" />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="service-bookings" element={<ServiceBooking />} />
          <Route
            path="service-bookings/details/:id"
            element={<ServiceBookingDetails />}
          >
            <Route index element={<Navigate to="service-information" />} />
            <Route path="service-information" element={<ServiceInfo />} />
            <Route
              path="additional-service-information"
              element={<AdditionalServiceInfo />}
            />
            <Route
              path="payment-information"
              element={<PaymentInformation />}
            />
            <Route path="service-feed" element={<ServiceFeed />} />
          </Route>
          <Route path="vkinnect-feed" element={<Feed />} />
          <Route path="vkinnect-associates" element={<Volunteers />} />
          <Route path="vkinnect-admin" element={<Admin />} />
          <Route path="vkinnect-admin/:id" element={<BasicInfo />} />
          <Route path="emergency-alerts" element={<EmergencyAlerts />} />
          <Route
            path="vkinnect-associates/details/:id"
            element={<VolunteerDetails />}
          >
            <Route index element={<Navigate to="basic-information" />} />
            <Route path="basic-information" element={<BasicInformation />} />
            <Route
              path="service-information"
              element={<ServiceInformation />}
            />
            <Route
              path="location-information"
              element={<LocationInformation />}
            />
            <Route path="kyc-information" element={<KycInformation />} />
            <Route path="documentation" element={<Documentation />} />
          </Route>
        </Route>
        <Route path="configuration" element={<ConfigLayout />}>
          <Route index element={<Navigate to="service-category" />} />
          <Route path="service-category" element={<ServiceCategory />} />
          <Route
            path="service-category/:id/services"
            element={<ServiceType />}
          />
          <Route path="services" element={<Services />} />
          <Route path="services/:id" element={<ServiceDetail />} />
          <Route path="locker" element={<Locker />} />
          <Route path="service-packages" element={<ServicePackages />} />
          <Route
            path="service-packages/:id"
            element={<ServicePackageDetail />}
          />
        </Route>
        <Route path="login" element={<Login />} />
      </Routes>
    </Router>
  );
};

export default RoutesContainer;
