import {
  alpha,
  Box,
  Table as MuiTable,
  styled,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { Loader } from "components/loader";
// import TableFooter from 'components/Mui/TableFooter'
import _ from "lodash";

export interface ColumnProps {
  dataIndex: string;
  key: string;
  title: any;
  render?: (cellData: any, row: any) => any;
  textColor?: string;
}

interface TableProps {
  columns: Array<ColumnProps>;
  rowKey?: string;
  dataSource?: any[];
  loading?: boolean;
  headAlign?: any;
  tableAlign?: any;
  onRowClick?: (row: any) => void;
  pagination?: {
    page: number;
    count?: number;
    onPageChange: any;
    rowsPerPage: number;
    onRowsPerPageChange: any;
  };
}

export default function Table({
  columns,
  dataSource,
  onRowClick,
  pagination,
  loading,
  headAlign,
  tableAlign,
}: TableProps) {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
    },
  }));

  return (
    <TableContainer
      sx={{
        borderRadius: "10px",
        border: "1px solid #1212121A",
        borderCollapse: "collapse",
        boxShadow: "0px 5px 15px #22222214",
      }}
    >
      <>
        <MuiTable sx={{ border: "none", borderCollapse: "collapse" }}>
          <TableHead sx={{ backgroundColor: "#F7FAFD" }}>
            <TableRow>
              {columns.map((col, index) => (
                <StyledTableCell align={headAlign} key={index}>
                  <Typography color="#ffffff">{col.title}</Typography>
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          {loading ? (
            <Box sx={{ width: "70vw" }}>
              <Loader />
            </Box>
          ) : (
            <>
              {dataSource?.length !== 0 ? (
                <TableBody>
                  {dataSource?.map((row, index) => (
                    <StyledTableRow
                      canRowClick={!!onRowClick}
                      hover={!!onRowClick}
                      key={index}
                      onClick={() => {
                        return onRowClick && onRowClick(row);
                      }}
                    >
                      {columns.map((col, colIndex) => (
                        <TableCell
                          align={tableAlign}
                          sx={{ color: col.textColor }}
                          key={colIndex}
                        >
                          {col?.render ? (
                            <Typography>
                              {col.render(row[col.dataIndex], row)}
                            </Typography>
                          ) : (
                            <Typography>{_.get(row, col.dataIndex)}</Typography>
                          )}
                        </TableCell>
                      ))}
                    </StyledTableRow>
                  ))}
                </TableBody>
              ) : (
                <Box>
                  <Typography sx={{ padding: "10px" }}>
                    No Records Found !..
                  </Typography>
                </Box>
              )}
            </>
          )}
        </MuiTable>
        {pagination && (
          <Box display="flex" justifyContent="end">
            <TablePagination
              count={pagination.count!}
              page={pagination.page}
              onPageChange={pagination.onPageChange}
              rowsPerPage={pagination.rowsPerPage}
              onRowsPerPageChange={pagination.onRowsPerPageChange}
              rowsPerPageOptions={[10, 20, 50]}
            />
          </Box>
        )}
      </>
    </TableContainer>
  );
}

const StyledTableRow = styled(TableRow)<{ canRowClick: boolean }>(
  ({ theme, canRowClick }) => ({
    ...(canRowClick && {
      cursor: "pointer",
      "&.MuiTableRow-hover:hover": {
        backgroundColor: alpha("#f2f2f2", 0.4),
      },
    }),
  })
);
