import { http } from "../http";

export const login = (data: any) => {
  return http.post("auth/admin-login", data);
};

export const getUsers = ({ queryKey }: any) => {
  return http.get("users", {
    params: {
      ...queryKey[1],
    },
  });
};

export const getUserById = ({ queryKey }: any) => {
  return http.get(`users/${queryKey[1]}`);
};

export const postUser = (data: any) => {
  return http.post("users", data);
};

export const updateUser = ({ userId, data }: any) => {
  return http.patch(`users/${userId}`, data);
};
