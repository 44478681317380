import addIcon from "./icons/add.png";
import AdminIcon from "./icons/Admin.png";
import attach from "./icons/attach.png";
import comment from "./icons/comment.png";
import configurationIcon from "./icons/configurationIcon.png";
import dashboardIcon from "./icons/dashboardIcon.png";
import deleteIcon from "./icons/delete.png";
import editIcon from "./icons/edit.png";
import emergencyAlertIcon from "./icons/emergencyAlertIcon.png";
import gallery from "./icons/gallery.png";
import like from "./icons/like.png";
import serviceBookingIcon from "./icons/serviceBookingIcon.png";
import servicesIcon from "./icons/servicesIcon.png";
import serviceCategory from "./icons/serviceCategory.png";
import smile from "./icons/smile.png";
import upload from "./icons/upload.png";
import vkinnectAssociatesIcon from "./icons/vkinnectAssociatesIcon.png";
import vkinnectFeedIcon from "./icons/vkinnectFeedIcon.png";
import admin from "./images/admin.png";
import appBarNotification from "./images/appBarNotification.png";
import appBarSettings from "./images/appBarSettings.png";
import assign from "./images/assign.png";
import DashboardColorIcon from "./images/DashboardColorIcon.png";
import DashboardWhiteIcon from "./images/DashboardWhiteIcon.png";
import EmptyDashboard from "./images/EmptyDashboard.png";
import loginBackground from "./images/loginBackground.png";
import logoAppBar from "./images/logoAppBar.png";
import logoLogin from "./images/logoLogin.png";
import pdf from "./images/pdf.png";
import pdfDownload from "./images/pdfDownload.png";
import WalletColorIcon from "./images/walletColorIcon.png";
import WalletWhiteIcon from "./images/walletWhiteIcon.png";
import services from "./icons/services.png";
import locker from "./icons/locker.png";
import servicePackage from "./icons/servicePackage.png";

export {
  DashboardColorIcon,
  DashboardWhiteIcon,
  EmptyDashboard,
  WalletColorIcon,
  WalletWhiteIcon,
  admin,
  appBarNotification,
  appBarSettings,
  loginBackground,
  logoAppBar,
  logoLogin,
  pdf,
  pdfDownload,
  assign,
};

export const icons = {
  configurationIcon,
  dashboardIcon,
  vkinnectFeedIcon,
  emergencyAlertIcon,
  serviceBookingIcon,
  servicesIcon,
  vkinnectAssociatesIcon,
  addIcon,
  editIcon,
  deleteIcon,
  like,
  comment,
  gallery,
  smile,
  attach,
  upload,
  AdminIcon,
  serviceCategory,
  services,
  locker,
  servicePackage,
};
