import SearchIcon from "@mui/icons-material/Search";
import { Box, IconButton, TextField } from "@mui/material";
import { getEmergencyAlert } from "api/services";
import { EmptyDashboard } from "assets";
import axios from "axios";
import EmptyPage from "components/EmptyPage";
import Table from "components/TableComponent";
import moment from "moment";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { StyledFlexBox } from "styles";

function EmergencyAlerts() {
  const [search, setSearch] = useState<any>("");
  const [limit, setLimit] = useState<number>(20);
  const [page, setPage] = useState<number>(0);
  const [newData, setNewData] = useState<any>([]);

  const { data, isLoading } = useQuery(
    [
      "emergencyAlerts",
      {
        search: search,
        limit: limit,
        offset: page * limit,
      },
    ],
    getEmergencyAlert
  );

  const getAddress = async ({ lat, lng }: any) => {
    try {
      const res = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
      );
      return res?.data?.results[0]?.formatted_address;
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (!isLoading) {
      const newData = data?.data?.data?.map(async (item: any) => {
        const address = await getAddress({
          lat: item.latitude,
          lng: item.longitude,
        });

        return {
          ...item,
          address,
        };
      });
      const item = new Promise((resolve, reject) => {
        resolve(Promise.all(newData));
      }).then((res) => {
        setNewData(res);
      });
    }
  }, [data?.data]);

  const column = [
    {
      title: "Family Member Name",
      dataIndex: "userName",
      key: "userName",
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
    },
    {
      title: "Time",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (colData: any) => {
        return moment(colData.createdAt).format("hh:mm:ss a");
      },
    },
  ];

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event: any) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box>
      <StyledFlexBox width="600px" mb={2} gap={2}>
        <TextField
          label="Search"
          fullWidth
          InputProps={{
            endAdornment: (
              <IconButton>
                <SearchIcon />
              </IconButton>
            ),
          }}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </StyledFlexBox>
      {data?.data?.count === 0 ? (
        <EmptyPage
          image={EmptyDashboard}
          title="There are no Emerygency Alerts"
        />
      ) : (
        <Table
          columns={column}
          headAlign={"left"}
          tableAlign={"left"}
          dataSource={newData}
          loading={isLoading}
          pagination={{
            page: page,
            rowsPerPage: limit,
            count: data?.data?.count,
            onPageChange: handlePageChange,
            onRowsPerPageChange: handleRowsPerPageChange,
          }}
        />
      )}
    </Box>
  );
}

export default EmergencyAlerts;
